import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
	CollectionItem,
	PackCardItem,
	PackItem,
	SinglePackItem,
	UrlItem,
} from '@/components/app/cards/PacksList/types';
import Image from '@/components/image/NextImage';
import { useAnalytics } from '@/hooks/analytics';
import { usePackItemAttributes } from '@/hooks/app/usePackItemAttributes';
import { useUserState } from '@/hooks/store';
import { useFeatureFlags } from '@/hooks/utils/useFeatureFlags';
import { formatResizedUrl } from '@/utils/app/formatResizedUrl';

import { CardSize } from '../CardCarousel/utils';
import messages from './messages';
import {
	BackgroundImage,
	CardContainer,
	Duration,
	ImageWrapper,
	InfoContainer,
	LockIcon,
	NewTag,
	Subtitle,
	Title,
} from './styles';
import { ContentCardProps } from './types';

export const getCardSize = (displayType: string): CardSize => {
	return ['banner-carousel', 'banner'].includes(displayType) ? 'banner' : 'default';
};

const MAX_CONTENT_CARD_IMAGE_WIDTH = 1000;

const createSubtitle = (field1?: string, field2?: string) => {
	if (field1 && field2) {
		return `${field1} • ${field2}`;
	}

	return field1 ?? field2;
};

export function isSupportedPackItem(
	packItem: CollectionItem | SinglePackItem | UrlItem | PackItem,
	hasSupportPackOfPacks: string,
): packItem is PackCardItem {
	return (
		(hasSupportPackOfPacks && packItem.type === 'pack') ||
		packItem.type === 'program' ||
		packItem.type === 'guide' ||
		packItem.type === 'url'
	);
}

const ContentCard = ({
	packFeedName,
	card,
	isHidden = false,
	onClick,
	displayType = 'default',
	pack,
	className,
	searchQuery,
}: ContentCardProps) => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const { flagValues, flagLoading } = useFeatureFlags('support-pack-of-packs-on-web');
	const hasSupportPackOfPacksOnWeb = !flagLoading && flagValues['support-pack-of-packs-on-web'];

	const {
		title,
		subtitle,
		handlePackItemClick,
		key,
		duration,
		is_new,
		is_unlocked,
		image,
		promotional_text,
		content_type,
		description,
		narrator_name,
		program_id,
		guide_id,
	} = usePackItemAttributes({
		packFeedName,
		pack,
		packItem: card,
		searchQuery,
		onClickOverride: onClick,
	});

	const subtitleLine1 = createSubtitle(content_type, narrator_name);
	const subtitleLine2 = createSubtitle(description, promotional_text);

	// work life balance packs analytics:
	const user = useUserState();
	const partnerId = user?.subscription?.b2b_details?.partner_id as string;
	const logWorkLifeBalanceEvent = () => {
		if (pack?.pack_class.includes('wlb')) {
			logEvent({
				eventName: 'Work Life Balance : Pack Item : Clicked',
				eventProps: {
					pack_class: pack?.pack_class,
					partner_id: partnerId,
					...(title ? { title } : {}),
					...(program_id || guide_id ? { id: program_id || guide_id } : {}),
					guide_id: guide_id ?? null,
				},
			});
		}
	};

	const imageSize = useMemo(() => {
		if (!image.width) {
			// if we don't know the width, set it to 1000 and don't use any height value
			// this will prevent the image from being cropped, returning it in the original aspect ratio
			return { width: MAX_CONTENT_CARD_IMAGE_WIDTH };
		}
		if (!image.height) {
			// if we don't know the height, request with just the image width (capping at 1000px) and no height value
			// this will prevent the image from being cropped, returning it in the original aspect ratio
			return { width: Math.min(MAX_CONTENT_CARD_IMAGE_WIDTH, image.width) };
		}
		// if we do know the width and height, request an image with the correct aspect ratio
		// scaled to be the same number of total pixels as a 1000x1000 square image
		const scalingFactor = MAX_CONTENT_CARD_IMAGE_WIDTH * Math.sqrt(image.height * image.width);
		const scaledWidth = scalingFactor / image.height;
		const scaledHeight = scalingFactor / image.width;
		return { width: Math.round(scaledWidth), height: Math.round(scaledHeight) };
	}, [image.width, image.height]);
	const cardSize = useMemo(() => getCardSize(displayType), [displayType]);
	const nextImageSizes = useMemo(() => {
		// These sizes were all derived from measuring the elements at our standard breakpoints and rounding up a bit
		if (cardSize === 'banner') {
			return '(min-width: 320px) 288px, (min-width: 480px) 320px, (min-width: 768px) 320px, (min-width: 1024px) 352px, (min-width: 1440px) 25vw, 100vw';
		}
		return '(min-width: 320px) 176px, (min-width: 480px) 208px, (min-width: 768px) 208px, (min-width: 1024px) 272px, (min-width: 1440px) 17vw, 100vw';
	}, [cardSize]);

	return (
		<CardContainer
			onClick={() => {
				handlePackItemClick().catch(() => {});
				logWorkLifeBalanceEvent();
			}} // Don't pass e to the above click event
			onKeyDown={e => {
				if (e.nativeEvent instanceof KeyboardEvent && e.key !== 'Enter' && e.key !== ' ') {
					return;
				}
				handlePackItemClick().catch(() => {});
			}}
			isHidden={isHidden}
			key={key}
			className={className}
			tabIndex={0}
			role="button"
			aria-label={formatMessage(messages.label, {
				title,
			})}
		>
			<ImageWrapper $cardSize={cardSize}>
				<BackgroundImage>
					<Image
						layout="fill"
						objectFit="cover"
						src={formatResizedUrl(image.url, imageSize.width, imageSize.height)}
						alt=""
						sizes={nextImageSizes}
					/>
				</BackgroundImage>
				{!is_unlocked && <LockIcon data-testid="lock-icon" />}
				{duration && <Duration>{duration}</Duration>}
				{is_new && <NewTag data-testid="new-icon">{formatMessage(messages.newTag)}</NewTag>}
			</ImageWrapper>
			<InfoContainer>
				{title && <Title el={pack?.title ? 'h3' : 'h2'}>{title}</Title>}
				{hasSupportPackOfPacksOnWeb && card.type === 'pack' && subtitle ? (
					<Subtitle isDescription>{subtitle ? subtitle : promotional_text}</Subtitle>
				) : (
					<>
						{subtitleLine1 && <Subtitle>{subtitleLine1}</Subtitle>}
						{subtitleLine2 && <Subtitle>{subtitleLine2}</Subtitle>}
					</>
				)}
			</InfoContainer>
		</CardContainer>
	);
};

export default ContentCard;
